import themes from "@client/styles/theming.module.css";
import React from "react";

import { QuotesBlock as QuotesBlockProps } from "@reactivated";

import { ImageChooserBlock } from "@thelabnyc/thelabui/src/components/ImageChooserBlock";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

import Carousel, { CarouselNavigationButtons } from "../Carousel";

import styles from "./index.module.scss";

export default function QuotesBlock(value: QuotesBlockProps) {
    const bgColor = `#${value.background_color ?? "ffffff"}`;

    const quoteLength = value.quotes?.length ? value.quotes.length : 0;

    return (
        <section
            className={styles.root}
            style={{
                backgroundColor: value.background_image ? undefined : bgColor,
            }}
        >
            {value.background_image && (
                <ImageChooserBlock
                    attrs={{ className: styles.backgroundPicture }}
                    imageAttrs={{
                        className: styles.backgroundImage,
                    }}
                    value={value.background_image}
                    sizes="100vw"
                />
            )}
            <Carousel
                label="Quotes from our client"
                controls={
                    <CarouselNavigationButtons
                        className={styles.quoteNav}
                        style="filled"
                    />
                }
                className={concatClassNames([
                    styles.quoteBlock,
                    quoteLength > 1 ? styles.quoteCarouselBlock : "",
                    value.text_color === "dark" ? themes.light : themes.dark,
                ])}
            >
                {value.quotes?.map((block) => {
                    return (
                        <React.Fragment key={block.id}>
                            <p className={styles.quote}>“{block.value.body}”</p>
                            <div
                                className={
                                    block.value.avatar
                                        ? styles.attributionWrapper
                                        : ""
                                }
                            >
                                {block.value.avatar && (
                                    <ImageChooserBlock
                                        attrs={{ className: styles.avatar }}
                                        imageAttrs={{
                                            className: styles.avatarImage,
                                        }}
                                        value={block.value.avatar}
                                        sizes="120px"
                                    />
                                )}
                                <p className={styles.attribution}>
                                    {block.value.attribution}
                                </p>
                            </div>
                        </React.Fragment>
                    );
                })}
            </Carousel>
        </section>
    );
}
